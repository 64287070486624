import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import EnhancedTable from "../../reusables/TableComponent/TableComponent";
import Service from "../../../services/httpService";
import { saveAs } from "file-saver";
import Modal from "react-bootstrap/Modal";
import { UserTypeContext as RowsContext } from "../../pages/RowsContext/RowsContext";
import CloseIcon from "@mui/icons-material/Close";
import CollegeData from "../../../CollegeDataServices/httpCollege";
import { useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Multiselect from "multiselect-react-dropdown";
import TopBar from "../../reusables/TopBar/Topbar";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import Papa from "papaparse";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../../reusables/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import { Color } from "../../../GlobalStyles";
import ExcelJS from "exceljs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalMentorDetails from "../../Dashboard/MentorStatus/ModalMentorDetails";
import { Typography } from "@mui/material";
const UserType = () => {
  const navigate = useNavigate();

  const collegeData = new CollegeData();

  const [searchTerms, setSearchTerms] = useState("");

  const [role_select_data, setRole_select_data] = useState([]);
  const [activity_select_data, setActivity_select_data] = useState([]);
  const [allowed_activity_select_data, setAllowed_activity_select_data] = useState([]);
  const [loading, setLoading] = useState(true);

  // useState for mentor details response
  const [mentorDetailsForUser_id, setMentorDetailsForUser_id] = useState();

  const [rowsData, setRowsData] = useState([]);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [originalMentorDetails, setOriginalMentorDetails] = useState([]);
  const [copyList, setCopyList] = useState(mentorDetails);

  const [roleTypeData, setRoleTypeData] = useState([]);
  const [roleTypeDataMulti, setRoleTypeDataMulti] = useState([]);
  const [activityTypeData, setActivityTypeData] = useState([]);
  const [allowed_activityTypeData, setAllowed_activityTypeData] = useState([]);

  const [activityTypeDataMulti, setActivityTypeDataMulti] = useState([]);
  const [allowedActivityTypeDataMulti, setAllowedActivityTypeDataMulti] = useState([]);

  const [activity_type_id, setActivity_type_id] = useState([]);
  const [allowed_activity_type, setAllowed_activity_type] = useState([]);
  const [role_type_id, setRole_type_id] = useState([]);
  const [formInputs, setFormInputs] = useState([{ id: null, first_name: "", last_name: "", email_id: "", ph_no: "", activity_type_ids: [], role_type_ids: [], allowed_activity_type: [], linkedin: "" }]);
  const [from_table, setFrom_table] = useState();
  const [openModal, setOpenModal] = useState(false);
  console.log("formInputs", formInputs);
  const location = useLocation();
  const service = new Service();

  // obtain college data
  const [mentor_college_id, setMentor_college_id] = useState();

  let count = 0;
  const headCells = [
    {
      id: "heading2",
      numeric: "right",
      disablePadding: false,
      label: "First Name",
    },
    {
      id: "heading2",
      numeric: "right",
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "heading2",
      numeric: "right",
      disablePadding: false,
      label: "Email Id",
    },
    {
      id: "heading2",
      numeric: "right",
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "heading2",
      numeric: "right",
      disablePadding: false,
      label: "LinkedIn",
    },
  ];
  let searched = location.state?.searching ? location.state?.searching : "Active";
  console.log("searched", searched);

  // filters options
  const [options, setOptions] = useState([
    { label: "All", value: "All", isSelected: false },
    { label: "Active", value: "Active", isSelected: false },
    { label: "Deactivated", value: "Deactivated", isSelected: false },
    { label: "Not activated", value: "Not activated", isSelected: false },
    { label: "Activated but not completed readiness", value: "Activated but not completed readiness", isSelected: false },
    { label: "Restricted", value: "Restricted", isSelected: false },
    { label: "Suspended - 48 hours", value: "Suspended - 48 hours", isSelected: false },
    { label: "Suspended - 72 hours", value: "Suspended - 72 hours", isSelected: false },
    { label: "Role not allocated", value: "Role not allocated", isSelected: false },
  ]);

  const [activeMentor, setActiveMentor] = useState();
  const [suspendedMentor, setSuspendedMentor] = useState();
  const [restrictedMentor, setRestrictedMentor] = useState();
  const [studentsDetailsClick, setStudentsDetailsClick] = useState({});
  const [activityActiveMentor, setActivityActiveMentor] = useState();
  // mentor details data getter
  const mentorDetailsGetter = async () => {
    try {
      if (!rowsData || !from_table?.index || !rowsData[from_table?.index].user_id) return;

      const res = await collegeData.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/detail?user_id=${rowsData[from_table?.index].user_id}`);
      console.log("Response from backend is11:", res.data);
      setMentorDetailsForUser_id(res.data);
      return res ? res.data : [];
    } catch (err) {
      console.log(err);
    }
  };

  const additionalMentorDetailsGetter = async () => {
    try {
      const res = await collegeData.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/detail?user_id=${rowsData[from_table?.index].user_id}`);
      console.log("Response from backend is11:", res.data);
      setMentorDetailsForUser_id(res.data);
      return res ? res.data : [];
    } catch (err) {
      console.log(err);
    }
  };
  console.log("setMentorDetails", mentorDetails);

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  const callModalToOpen = () => {
    console.log("Modal open fucntion called");
    setOpenModal(true);
  };

  const data_getter = (name, id, index, activity) => {
    console.log("id from data_getter", id);
    setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined });
  };

  useEffect(() => {
    if (searchTerms === "") {
      setRowsData(originalMentorDetails);
    }
  }, [searchTerms]);

  // get request
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      //college getter
      let mentorUserDetails = await mentorUserDetailsGetter();
      let activityType = await activityTypeGetter();
      let roleType = await roleTypeGetter();

      // forming the data
      console.log("odonil", { mentorUserDetails });
      data_manupulator_activityType(activityType);
      data_manupulator_roleType(roleType);

      // setSearchTerms(searched)
      setLoading(false);
    };

    handleEffect();
  }, []);

  // mentor user table details getter
  const mentorUserDetailsGetter = async () => {
    try {
      const res = await service.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`);
      console.log("mentorUserDetailsGetter", res.data);
      const transformedData = res.data.map((item) => ({
        ...item,
        allowed_activity_name: item.allowed_activity_name ? item.allowed_activity_name.split(",") : null,
        role_name: item.role_name ? item.role_name.split(",") : null,
        activity_name: item.activity_name ? item.activity_name.split(",") : null,
      }));

      setRowsData(transformedData);
      setOriginalMentorDetails(transformedData);
      console.log("searched", searched);
      handleFilters(searched, transformedData);
      setMentorDetails([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const activityTypeGetter = async () => {
    try {
      const res = await service.get("/activityType");
      console.log("activityTypeGetter", res.data);
      setActivityTypeData([...res.data]);
      setAllowed_activityTypeData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  console.log("activityyyy", activityTypeData);
  const roleTypeGetter = async () => {
    try {
      const res = await service.get("/role?is_admin=1");
      console.log("roleTypeGetter", res.data);
      setRoleTypeData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const setting_data = async () => {
      let mentorDetails = await mentorDetailsGetter();
      let additionalMentorDetails = await additionalMentorDetailsGetter();
      if (mentorDetails) {
        console.log("ment deatils", mentorDetails);
        const activityType = JSON.parse(mentorDetails?.activity_type);
        const activityTypeIds = activityType.activity_type_ids;
        console.log("activityTypeIds", activityTypeIds);
        setActivity_type_id(activityTypeIds);

        const allowedActivityType = JSON.parse(mentorDetails?.allowed_activity_type);
        const allowedActivityTypeIds = allowedActivityType.allowed_activity_type;
        setAllowed_activity_type(allowedActivityTypeIds);

        const roleType = JSON.parse(mentorDetails?.role_type);
        const roleTYpeIds = roleType.role_type_ids;
        setRole_type_id(roleTYpeIds);
        console.log("activity type idsss", activity_type_id);
        console.log("role type idsss", role_type_id);
        console.log("allowed activity type idsss", setAllowed_activity_type);

        //setting values for Edit Modal
        console.log("Inside else of useEffect");
        console.log({ rowsData });

        // allowed activity mapping
        let mappingValue = allowedActivityTypeDataMulti.map((testing) => ({ id: testing.id, act_type_name: testing.act_type_name }));
        let mappingFiltered = mappingValue.filter((item_s) => allowedActivityTypeIds.includes(item_s.id));
        setAllowed_activity_select_data(mappingFiltered);

        // activity mpping
        let mappingActivityValue = activityTypeDataMulti.map((testing) => ({ id: testing.id, act_type_name: testing.act_type_name }));
        let mappingFilteredActivityValue = mappingActivityValue.filter((item_s) => activityTypeIds.includes(item_s.id));
        setActivity_select_data(mappingFilteredActivityValue);

        // activity mpping role_type_id

        let mappingRoleType = roleTypeDataMulti.map((testing) => ({ id: testing.id, name: testing.name }));
        let mappingFilteredRoleType = mappingRoleType.filter((item_s) => roleTYpeIds.includes(item_s.id));
        setRole_select_data(mappingFilteredRoleType);

        console.log("activity_type_id", activity_select_data);
        console.log("role_type_id", role_select_data);
        console.log("allowed_activity_type_id", allowed_activity_select_data);

        setFormInputs({ ...formInputs, id: rowsData[from_table?.index]?.user_id, first_name: rowsData[from_table?.index]?.first_name, linkedin: rowsData[from_table?.index]?.linkedin, last_name: rowsData[from_table?.index]?.last_name, email_id: rowsData[from_table?.index]?.email_id, ph_no: rowsData[from_table?.index]?.ph_no, activity_type_ids: activityTypeIds, role_type_ids: roleTYpeIds, allowed_activity_type: allowedActivityTypeIds });
      }
    };
    setting_data();
  }, [from_table]);

  const data_manupulator_mentorDetails = (mentorDetails) => {
    let temp = mentorDetails;
    temp = temp?.map((bat) => {
      // bat.activity_type = bat.activity_type;
      bat.id = bat.user_id;
      // bat.role_type = bat.role_type
      return bat;
    });
    console.log("temp rows college:", temp);
  };

  const data_manupulator_activityType = (activityType) => {
    console.log("SKU data:", activityType);
    let temp_bundle = JSON.parse(JSON.stringify(activityType));
    // temp_bundle = temp_bundle.map((bundle) => {
    //   // bundle.act_type_id = temp_activity?.map(act => act.id === bundle.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
    //   bundle.table = "bundle"
    //   return bundle
    // });
    console.log({ temp_bundle });
    temp_bundle = temp_bundle.map((pro) => {
      pro.key = pro.act_type_name;
      pro.id = pro.id;

      return pro;
    });
    console.log("temp bundle:", temp_bundle);
    setActivityTypeDataMulti(temp_bundle);
    setAllowedActivityTypeDataMulti(temp_bundle);
  };

  console.log("activity multiple", activityTypeDataMulti);

  const handleStatusDetails = async (mentor_id) => {
    try {
      const res = await service.get(`/mentor/status?user_id=${mentor_id}`);
      console.log("mentor status result", res.data);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleStudentsDetailsClick = async (mentor_id) => {
    console.log("modal open true or false", openModal);
    try {
      let mentorStatusDetails = await handleStatusDetails(mentor_id);
      console.log(":mentor response", mentorStatusDetails);
      setActiveMentor(mentorStatusDetails.active);
      setRestrictedMentor(mentorStatusDetails.restricted);
      setSuspendedMentor(mentorStatusDetails.suspended);
      setActivityActiveMentor(mentorStatusDetails.active_flag);
      const res = await service.get(`/mentor/sessionDetails?mentor_id=${mentor_id}`);
      console.log("mentor session", res.mentorList);
      const temp_data = {
        ...res.mentorList[0],
        mentor_id: mentor_id,
      };
      setStudentsDetailsClick(temp_data);
      // setMentorStatus(mentorStatusDetails)

      callModalToOpen();
    } catch (err) {
      console.log(err);
    }
  };

  const data_manupulator_roleType = (roleType) => {
    console.log("SKU data:", roleType);
    let temp_sku = JSON.parse(JSON.stringify(roleType));
    temp_sku = temp_sku.map((sku) => {
      // sku.act_type_id = temp_activity?.map(act => act.id === sku.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
      sku.table = "sku";
      return sku;
    });

    console.log("temp sku:", temp_sku);
    setRoleTypeDataMulti(temp_sku);
  };

  const handlePutRequest = async (id) => {
    console.log("");
    //get request here
    service
      .put(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/detail?user_id=${rowsData[from_table?.index].user_id}`, {
        user_id: rowsData[from_table?.index].user_id,
        active_flag: 1,
        allowed_activity_type: { allowed_activity_type: allowed_activity_type },
        activity_type: {
          activity_type_ids: activity_type_id,
        },
        role_type: {
          role_type_ids: role_type_id,
        },
        stats: {},
        avg_rating: {},
        no_of_rated_exp: {},
        linkedin: formInputs.linkedin,
      })
      .then((res) => {
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation");
        let temp = JSON.parse(JSON.stringify(setMentorDetails));
        console.log("temp data", temp);
        temp[from_table?.index].activity_type_ids = formInputs.activity_type_ids;
        temp[from_table?.index].role_type_ids = formInputs.role_type_ids;
        temp[from_table?.index].allowed_activity_type = formInputs.allowed_activity_type;
        console.log("Put temp value:", temp);
        setRowsData(temp);
      })
      .catch((err) => {
        // console.log("Errog Msg: ", err);
        window.alert(err.response.data.message);
      });
  };

  const Modal_Durations = (show, setShow) => {
    const modalSubmit = () => {
      handlePutRequest(mentorDetails[from_table?.index]?.id);
      setShow(false);
    };

    const onHide = () => {
      setShow(false);
    };

    const handleChange = (e, index) => {
      console.log("name from form  is:", e.target.name);
      console.log("code from form  is:", e.target.value);
      setFormInputs((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    };

    // const onSelect_roleType = (e) => {
    //   console.log(e)
    //   setFormInputs({ ...formInputs, role_type_ids: e })
    // }
    // const onRemove_roleType = (e) => {
    //   console.log(e)
    //   setFormInputs({ ...formInputs, role_type_ids: e })

    // }
    // const onSelect_activityType = (e) => {
    //   console.log(e)
    //   setFormInputs({ ...formInputs, activity_type_ids: e })

    // }
    const onSelect_activityType = (e) => {
      console.log("Added an item to multi select - activity-", e);
      setFormInputs({ ...formInputs, activity_type_ids: e });
      let temp = e;
      temp = temp.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setActivity_type_id(temp);
      console.log("temp inside onSelect:", temp);
    };

    const onSelect_allowedActivityType = (e) => {
      console.log("Added an item to multi select - allowed activity -", e);
      setFormInputs({ ...formInputs, allowed_activity_type: e });
      let temp = e;
      temp = temp.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setAllowed_activity_type(temp);
      console.log("temp inside onSelect:", temp);
    };

    const onSelect_roleType = (e) => {
      console.log("Added an item to multi select - program -", e);
      setFormInputs({ ...formInputs, role_type_ids: e });
      let temp_id = e;
      temp_id = temp_id.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setRole_type_id(temp_id);
    };

    const onRemove_activityType = (e) => {
      console.log("Removed an item to multi select- activity");
      setFormInputs({ ...formInputs, activity_type_ids: e });
      let temp = e;
      temp = temp.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setActivity_type_id(temp);
      console.log("temp inside onSelect:", temp);
    };

    const onRemove_allowedActivityType = (e) => {
      console.log("Removed an item to multi select - allowed activity");
      setFormInputs({ ...formInputs, allowed_activity_type: e });
      let temp = e;
      temp = temp.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setAllowed_activity_type(temp);
      console.log("temp inside onSelect:", temp);
    };
    const onRemove_roleType = (e) => {
      console.log("Removed an item to multi select");
      setFormInputs({ ...formInputs, role_type_ids: e });
      let temp_id = e;
      temp_id = temp_id.filter((data) => typeof data.id === "number").map((obj) => obj.id);
      setRole_type_id(temp_id);
    };
    console.log("mentor_college_id", mentor_college_id);

    //render
    return (
      <Modal show={show} onHide={() => onHide()} className="FileUploadModal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}>
        {console.log("from_table", from_table)}
        <Modal.Body
          style={{
            position: "absolute",
            left: "60%",
            transform: "translate(-50%, -50%)",
            lineHeight: "1.4",
            padding: "14px 28px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "95%",
            minWidth: "500px",
            minHeight: "300px",
          }}
        >
          <form className="Entire-form" style={{ display: "flex", flexDirection: "col" }}>
            <div className="right-side-buttons-college row">
              <div className="headerComp">
                <CloseIcon className="closeIcon" onClick={(e) => onHide(e)} />
                <p className="compTitle">Mentor Details</p>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                <div className="form-group">
                  <label className="inputLabel">
                    First Name<span>*</span>
                  </label>
                  <input disabled={true} name="first_name" type="text" className="form-control" placeholder="Example input" value={formInputs.first_name} onChange={(e) => handleChange(e)} />
                </div>
                <div className="form-group">
                  <label className="inputLabel">
                    Last Name<span>*</span>
                  </label>
                  <input disabled={true} name="last_name" type="text" className="form-control" placeholder="Example input" value={formInputs.last_name} onChange={(e) => handleChange(e)} />
                </div>
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  Email<span>*</span>
                </label>
                <input disabled={true} name="email" type="text" className="form-control" placeholder="Example input" value={formInputs.email_id} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  Phone Number<span>*</span>
                </label>
                <input disabled={true} name="ph_no" type="text" className="form-control" placeholder="Example input" value={formInputs.ph_no} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  LinkedIn<span>*</span>
                </label>
                <input name="linkedin" type="text" className="form-control" placeholder="Example input" value={formInputs.linkedin} onChange={(e) => handleChange(e)} />
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  Role Type<span>*</span>
                </label>
                <Multiselect displayValue="name" selectedValues={role_select_data} onRemove={(e) => onRemove_roleType(e)} onSelect={(e) => onSelect_roleType(e)} options={roleTypeDataMulti} />
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  Allowed Activity Type<span>*</span>
                </label>
                {console.log("value", formInputs.allowed_activity_type)}
                {console.log("value", allowed_activity_select_data)}
                <Multiselect displayValue="act_type_name" selectedValues={allowed_activity_select_data} onRemove={(e) => onRemove_allowedActivityType(e)} onSelect={(e) => onSelect_allowedActivityType(e)} options={allowedActivityTypeDataMulti} />
              </div>
              <div className="form-group">
                <label className="inputLabel">
                  Activity Type<span>*</span>
                </label>
                <Multiselect displayValue="act_type_name" selectedValues={activity_select_data} onRemove={(e) => onRemove_activityType(e)} onSelect={(e) => onSelect_activityType(e)} options={activityTypeDataMulti} />
              </div>
              {console.log("role_type_ids", formInputs.role_type_ids)}
              <div className=" btn-comp " style={{ marginTop: "4%", marginLeft: "0" }}>
                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };

  const requestSearch = (searched) => {
    setSearchTerms(searched);

    const filteredList = originalMentorDetails.filter((item) => (item.first_name && item.first_name.toLowerCase().includes(searched.toLowerCase())) || (item.last_name && item.last_name.toLowerCase().includes(searched.toLowerCase())) || (item.email_id && item.email_id.toLowerCase().includes(searched.toLowerCase())) || (item.linkedin && item.linkedin.toLowerCase().includes(searched.toLowerCase())) || (item.allowed_activity_name && item.allowed_activity_name.length > 0 && item.allowed_activity_name.some((activity) => activity.toLowerCase().includes(searched.toLowerCase()))) || (item.role_name && item.role_name.length > 0 && item.role_name.some((role) => role.toLowerCase().includes(searched.toLowerCase()))) || (item.activity_name && item.activity_name.length > 0 && item.activity_name.some((activity) => activity.toLowerCase().includes(searched.toLowerCase()))));
    setRowsData(filteredList);
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      requestSearch(e.target.value);
    }
  };

  const handleFilters = (searched, originalMentorDetails) => {
    setSearchTerms(searched);

    const updatedOptions = options.map((option) => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false,
    }));

    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);

    let filteredList;
    switch (searched) {
      case "All":
        // filteredList = originalMentorDetails
        setRowsData(originalMentorDetails);
        break;
      case "Active":
        filteredList = originalMentorDetails.filter((item) => item.is_verified === 1 && item.active_flag === 1);
        setRowsData(filteredList);
        break;
      case "Deactivated":
        filteredList = originalMentorDetails.filter((item) => item.is_verified === 1 && item.active_flag === 0);
        setRowsData(filteredList);
        break;
      case "Not activated":
        filteredList = originalMentorDetails.filter((item) => item.is_verified === 0);
        setRowsData(filteredList);
        break;
      case "Activated but not completed readiness":
        filteredList = originalMentorDetails.filter((item) => item.is_verified === 1 && item.allowed_activity_name?.length === 0);
        setRowsData(filteredList);
        break;
      case "Restricted":
        filteredList = originalMentorDetails.filter((item) => JSON.parse(item.mentor_status).total <= JSON.parse(item.mentor_status).missed);
        setRowsData(filteredList);
        break;
      case "Suspended - 72 hours":
        filteredList = originalMentorDetails.filter((item) => item.suspended === "Blocked");
        setRowsData(filteredList);
        break;
      case "Suspended - 48 hours":
        filteredList = originalMentorDetails.filter((item) => item.suspended === "Partial");
        setRowsData(filteredList);
        break;
      case "Role not allocated":
        filteredList = originalMentorDetails.filter((item) => item.role_name?.length === 0 || item.role_name === null);
        setRowsData(filteredList);
        break;
      default:
        filteredList = originalMentorDetails;
    }
    console.log("filtered list", filteredList);
    // Update rowsData state only after filters are applied
  };

  // const fileDownload = () => {
  //   console.log("Downloading the file");
  //   console.log("Rows data now is:  ", rowsData);
  //   const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
  //   const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  //   saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
  // }

  const fileDownload = () => {
    console.log("Downloading the file");

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    const hardcodedRow = ["user_id", "first_name", "last_name", "email_id", "ph_no", "is_verified", "active_flag", "allowed_activity_name", "role_name", "activity_name", "college_name", "linkedIn", "suspended", "mentor_status"];

    // Add the column headers
    worksheet.addRow([...hardcodedRow]);

    // Add data rows
    rowsData.forEach((row, index) => {
      const rowData = [...Object.values(row)];
      worksheet.addRow(rowData);
    });

    // Save workbook to a Buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Save the Blob as a downloadable file
      const currentDate = new Date();
      const filename = `Mentor_${searchTerms}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      saveAs(blob, filename);
    });
  };

  return (
    <div>
      <RowsContext.Provider value={rowsData}>
        <TopBar />
        <SideNavigationBar />

        {loading ? (
          <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
            <Loader show={loading} />
          </div>
        ) : (
          <>
            {console.log({ rowsData })}
            <div style={{ marginTop: "5rem", width: "89%", marginLeft: "auto" }}>
              <div style={{ display: "flex" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem", marginBottom: "1rem" }} onClick={() => goBack()} />{" "}
                <Typography textAlign={"center"} sx={{ fontSize: "24px" }}>
                  Mentor Details
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <div className="searchTab" style={{ display: "flex", alignItems: "center", width: "90%", height: "30px", marginBottom: ".5rem" }}>
                  <input style={{ border: "none", height: "30px" }} className="searchingText searchTab" type="text" onChange={(e) => requestSearch(e.target.value)} placeholder="Search for name, roll number, etc" onKeyDown={(e) => handleEnterSearch(e)} />
                </div>
                <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
              </div>
              <div>
                {options.map((option, index) => (
                  <button
                    key={index}
                    className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                    style={{}}
                    onClick={() => {
                      handleFilters(option.value, originalMentorDetails);
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "-70px" }}>
              <EnhancedTable approved_flag="NOTA" data_getter={data_getter} mainFile_modal={Modal_Durations} headCells={headCells} count={count} fileDownload={fileDownload} path={window.location.pathname} handleStudentsDetailsClick={handleStudentsDetailsClick} />
            </div>
          </>
        )}
      </RowsContext.Provider>
      {openModal ? <ModalMentorDetails roleTypeData={roleTypeData} activityTypeData={activityTypeData} studentsDetailsClick={studentsDetailsClick} setStudentsDetailsClick={setStudentsDetailsClick} show={openModal} setShow={setOpenModal} activeMentor={activeMentor} suspendedMentor={suspendedMentor} restrictedMentor={restrictedMentor} activityActiveMentor={activityActiveMentor} /> : <></>}
    </div>
  );
};

export default UserType;
