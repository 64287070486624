import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import "./ModalInputUploadStyle.css"
import Modal from 'react-bootstrap/Modal';
import { UserTypeContext as RowsContext } from '../../pages/RowsContext/RowsContext'
import DeleteIcon from '@mui/icons-material/Delete';
import Service from '../../../services/httpService'
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
import ModalConfirmation from "./ModalConfirmation";
import { WithContext as ReactTags } from 'react-tag-input';
import { TagsInput } from "react-tag-input-component";
import Multiselect from 'multiselect-react-dropdown';
// const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function ModalInputUpload(props) {
    const rowsData = useContext(RowsContext);
    const service = new Service()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)
    const [fileType, setFileType] = useState("")
    const [fileIndex, setFileIndex] = useState(0)
    const [user_type, setUser_type] = useState("")
    // view modal input


    const { show, setShow, headCells, from_table, path, data_getter, file_Getter, action, showModalView, setshowModalView } = props;
    console.log("props coming", props);
    console.log("from_table", from_table);
    let filtered_data = {};
    if (from_table?.id !== null && from_table?.id !== undefined && from_table?.id !== "") {
        filtered_data = rowsData.filter(item => item?.id === from_table?.id);
        console.log("Filtered data from the ModalInpuUpload Page", filtered_data);

        //setFormData({ ...formData, act_type_name: filtered_data[0].act_type_name, type: filtered_data[0].type, description: filtered_data[0].description });
    }
    console.log("filtered data", filtered_data);
    const [show_data, setShowData] = useState({})
    const [formData, setFormData] = useState({
        id: from_table ? from_table?.id : "",
        index: from_table ? from_table?.index : "",
        act_type_name: filtered_data?.length > 0 ? filtered_data[0].act_type_name : "",
        type: filtered_data?.length > 0 ? filtered_data[0].type : "",
        description: filtered_data?.length > 0 ? filtered_data[0].description : "",
        allowed_durations: filtered_data?.length > 0 ? JSON.parse(filtered_data[0].allowed_durations)?.durations : "",
        image_url: filtered_data?.length > 0 ? filtered_data[0].image_url : null,
        image_version: null,
        mentor_material: { "pdf": [], "audio": [], "video": [] },
        student_material: { "pdf": [], "audio": [], "video": [] }
    })
    const [imageFiles, setImageFiles] = useState(null)
    const [imageName, setImageName] = useState('');
    const [mentorFiles, setMentorFiles] = useState({ "pdf": [], "audio": [], "video": [] });
    const [studentFiles, setStudentFiles] = useState({ "pdf": [], "audio": [], "video": [] });

    const [updatedForm, SetUpdatedForm] = useState({
        mentor_material: { "pdf": [], "audio": [], "video": [] },
        student_material: { "pdf": [], "audio": [], "video": [] }
    });
    const [updatedImage, setUpdatedImage] = useState(null)

    const [select_duration, setSelect_duration] = useState([]);
    const [durationData, setDurationData] = useState([]);
    const [post_duration_data, setPost_durationData] = useState([]);

    const [deleteFile, setDeleteFile] = useState([])




    useEffect(() => {
        console.log("Get request")
        const handleEffect = async () => {
            let temp_data = data_getter("", formData.act_type_name, formData.type, formData.description, from_table?.index, mentorFiles, studentFiles, action, "", "", "", imageFiles,)
            console.log("asdfghjkl", temp_data)
            setShowData(temp_data)
            //getting durations
            let duration = await durationGetter()
            console.log("After getting durations", duration)
            setDurationData(duration)
            // setSelect_duration(duration)
            if (action !== "create") {

                setMentorFiles(JSON.parse(temp_data?.mentor_material));
                setStudentFiles(JSON.parse(temp_data?.student_material));
                setImageFiles(temp_data?.image_url)
                durationNameGetter(duration)

                // setSelect_duration({ duration_name: formData?.allowed_durations[0] })
                // setImageName(temp_data?.image_url?.name)
                console.log({ temp_data })
            }
            else {
                setSelect_duration() // set the selected data in duration to none
            }

        }
        handleEffect();
    }, [])


    console.log({ durationData })
    console.log({ formData })
    console.log({ select_duration })

    //getting Durations
    const durationGetter = async () => {
        try {
            const res = await service.get('/duration')
            console.log("Duration RESPONSE:", res.durations)
            // let temp = JSON.parse(JSON.stringify(res.durations));
            // temp = temp.map(dur => {
            //     dur.key = dur.duration_value;
            //     dur.id = dur.duration_name;

            //     delete dur.duration_name;
            //     delete dur.duration_value;
            //     return dur;
            // });
            // console.log("temp rows program:", temp)

            return res.durations
            // setDurationData(temp)

        } catch (err) {
            console.log(err)
        }
    }

    //getting the Duration names from GET of Activity
    const durationNameGetter = (durat) => {
        let select_data = JSON.parse(JSON.stringify(formData?.allowed_durations)) //so that it doesnt reference and change the data
        let duration = JSON.parse(JSON.stringify(durat)) //so that it doesnt reference and change the data
        console.log("durationNameGetter ~ data", select_data)
        // let duration = JSON.parse(JSON.stringify(durationData)) //so that it doesnt reference and change the data
        console.log("durationNameGetter ~ duration", duration)
        select_data = select_data.map(row => {
            console.log((parseInt(row)))
            return {
                duration_name: duration.map(data => data.duration_name === row ? data.duration_name : "").filter(value => value !== "")[0],
                duration_value: duration.map(data => data.duration_name === row ? data.duration_value : "").filter(value => value !== "")[0]
            }
        });
        // let json_select_data = select_data.map((item) => {
        //     return {
        //         duration_name: item
        //     };
        // });

        console.log("Select data:", select_data)
        // console.log("JSON Select data:", json_select_data)
        setSelect_duration(select_data)


        let temp_value = JSON.parse(JSON.stringify(select_data));
        temp_value = temp_value.filter(data => typeof data.duration_name !== "number").map(obj => obj.duration_name)
        console.log("temp inside onSelect:", temp_value)
        setPost_durationData(temp_value) //data to be sent to the backend ~ post/put call
    }


    const SubmitForm = async (e) => {
        console.log("Submit Form ");
        console.log("ID from the formData", formData.id);

        //for inserting new rows
        if ((action === "create")) {
            console.log("Passed")
            const Post_res = data_getter("", formData.act_type_name, formData.type, formData.description, "", mentorFiles, studentFiles, "", "", "", "", imageFiles, post_duration_data)

            console.log("Post request from Modal Input Upload ", Post_res);
        }
        //for the edit option only
        else if ((action === "edit")) {

            console.log("PUT request form the Modal nInput Upload");
            // const data_getter = (id, name, type, description, index, files, studentFiles, action, updatedMentorForm, updatedStudentForm) => {
            const Put_Req = data_getter(formData.id, formData.act_type_name, formData.type, formData.description, formData.index, updatedForm.mentor_material, updatedForm.student_material, "put", "", "", "", updatedImage, post_duration_data)
            console.log("Post request from Modal Input Upload ", Put_Req);
        }

        setShow(false)
    }

    const onHide = () => {
        setShow(false)
    }

    // file upload
    console.log("iterable", mentorFiles);

    // file upload
    console.log();
    const handleFileInput = (e, fileType, index) => {
        console.log("fileType", { fileType }, index);
        const files = e.target.files;
        const filesArray = mentorFiles[fileType];
        // for (let i = 0; i < files.length; i++) {
        filesArray?.push(files[0]);
        // }
        const x = { ...mentorFiles, [fileType]: filesArray }
        // x[`${fileType}`] = filesArray;
        setMentorFiles(x);


        if (action === "edit") {
            // const y ={...updatedForm.mentor_materail, [fileType]: e.target.files}\\
            let temp_mm = { ...updatedForm }
            let temp_arr = temp_mm.mentor_material[fileType]
            console.log("updated value", temp_arr);
            temp_arr.push(files[0])
            temp_mm.mentor_material[fileType] = temp_arr
            SetUpdatedForm(temp_mm);

        }


    };
    console.log("updated value", updatedForm);
    const handleFileInputStudent = (e, fileType, index) => {
        console.log("fileType", { fileType }, index);
        const files = e.target.files;
        const filesArray = studentFiles[fileType];
        // for (let i = 0; i < files.length; i++) {
        filesArray?.push(files[0]);
        // }
        const x = { ...studentFiles, [fileType]: filesArray }
        // x[`${fileType}`] = filesArray;
        setStudentFiles(x);


        if (action === "edit") {
            // const y ={...updatedForm.mentor_materail, [fileType]: e.target.files}\\
            let temp_mm = { ...updatedForm }
            let temp_arr = temp_mm.student_material[fileType]
            console.log("updated value", temp_arr);
            temp_arr.push(files[0])
            temp_mm.student_material[fileType] = temp_arr
            SetUpdatedForm(temp_mm);

        }
    };

    const handleRemoveFile = (e, fileType, fileIndex) => {
        setShowDeleteModal(true)
        setFileType(fileType)
        setFileIndex(fileIndex)
        setUser_type("mentor")
        setDeleteFile(mentorFiles)


        // let filesArray = [...mentorFiles[fileType]];
        // filesArray = filesArray.filter((file, index) => (index !== parseInt(fileIndex)))
        // setMentorFiles({ ...mentorFiles, [fileType]: filesArray });
        // console.log("filesa arrat", filesArray, fileType, fileIndex);


        // data_getter(formData.id, formData.act_type_name, formData.type, formData.description, formData.index, {, {}, "delete", "mentor", fileType, fileIndex);
    };

    const handleRemoveStudentFile = (e, fileType, fileIndex) => {
        setShowDeleteModal(true)
        setFileType(fileType)
        setFileIndex(fileIndex)
        setUser_type("student")
        setDeleteFile(studentFiles)

        // let filesArray = [...studentFiles[fileType]];
        // filesArray = filesArray.filter((file, index) => (index !== parseInt(fileIndex)))
        // setMentorFiles({ ...studentFiles, [fileType]: filesArray });
        // console.log("filesa arrat", filesArray, fileType, fileIndex);
        // data_getter(formData.id, formData.act_type_name, formData.type, formData.description, formData.index, {}, {}, "delete", "student", fileType, fileIndex);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImageFiles(file);
        setImageName(file.name);

        if (action === "edit") {
            // const y ={...updatedForm.mentor_materail, [fileType]: e.target.files}\\
            let temp_mm = { ...updatedImage }
            temp_mm = event.target.files[0];
            setUpdatedImage(temp_mm);
            console.log("updated image", temp_mm);


        }


    };

    console.log("image files", { imageFiles });

    const handleRemoveImage = () => {
        // setShowDeleteModal(true)
        setShowImageDeleteModal(true)

    };

    //Multi Input tag - Duration
    // const handleChangeTags = (value) => {
    //     console.log("Tags event target value:", value)
    //     const intArray = value.map(str => parseInt(str, 10));
    //     console.log("handleChangeTags ~ temp", intArray)
    //     setIntTags(intArray)
    // }

    const onSelect_durations = (e) => {
        console.log("Added an item to multi select - program -", e)
        setSelect_duration(e);

        let temp_value = JSON.parse(JSON.stringify(e));
        temp_value = temp_value.filter(data => typeof data.duration_name !== "number").map(obj => obj.duration_name)
        console.log("temp inside onSelect:", temp_value)
        setPost_durationData(temp_value) //data to be sent to the backend ~ post/put call
    }

    const onRemove_durations = (e) => {
        console.log("Removed an item to multi select")
        setSelect_duration(e);

        let temp_value = JSON.parse(JSON.stringify(e));
        temp_value = temp_value.filter(data => typeof data.duration_name !== "number").map(obj => obj.duration_name)
        console.log("temp inside onSelect:", temp_value)
        setPost_durationData(temp_value)  //data to be sent to the backend ~ post/put call

    }

    // const handleDelete = i => {
    //     setTags(tags.filter((tag, index) => index !== i));
    // };

    // const handleAddition = tag => {
    //     console.log("handleAddition ~ tag", tag)
    //     setTags([...tags, tag]);
    // };

    // const handleDrag = (tag, currPos, newPos) => {
    //     const newTags = tags.slice();

    //     newTags.splice(currPos, 1);
    //     newTags.splice(newPos, 0, tag);

    //     // re-render
    //     setTags(newTags);
    // };

    // const handleTagClick = index => {
    //     console.log('The tag at index ' + index + ' was clicked');
    // };



    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            className="FileUploadModal"
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backdropFilter: "blur(5px)", minWidth: "80%", maxWidth: "95%", minHeight: "80%", maxHeight: "95%" }}
        >
            <Modal.Body style={{
                position: 'absolute',
                left: '60%',
                height: 'auto',
                transform: 'translate(-50%, -50%)',
                lineHeight: '1.4',
                padding: '14px 28px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                maxWidth: '95%',
                minWidth: '1000px',
                minHeight: '500px'
            }}>
                <div>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col', justifyContent: 'center' }}>

                        {/* input forms here */}
                        <div className='right-side-buttons row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                <p className="compTitle">Input Form</p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="form-group">
                                    <label className="inputLabel">Activity Type Name<span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Example input" onChange={(name) => { setFormData({ ...formData, act_type_name: name.target.value }) }} value={formData.act_type_name || ""} />
                                </div>
                                <div className="form-group " style={{ marginLeft: 12 }} >
                                    <label className="inputLabel">Type<span>*</span></label>
                                    <select className="form-control inputFields" onChange={(name) => { setFormData({ ...formData, type: name.target.value }) }} value={formData.type || ""}>
                                        <option className="form-control inputFields" value="">Choose Type</option>
                                        <option className="form-control inputFields" value="1 x 1">1 x 1</option>
                                        <option className="form-control inputFields" value="Group">Group</option>
                                        <option className="form-control inputFields" value="Subscription">Subscription</option>
                                        <option className="form-control inputFields" value="RDS">RDS</option>
                                        <option className="form-control inputFields" value="Test">Test</option>
                                        <option className="form-control inputFields" value="RDV">RDV</option>

                                    </select>
                                    {/* <label className="inputLabel">Type<span>*</span></label>
                            <input type="text" className="form-control inputFields" placeholder="Example input" onChange={(name) => { setFormData({ ...formData, type: name.target.value }) }} value={formData.type || ""} /> */}
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="inputLabel">Duration(min)<span>*</span></label>
                                <TagsInput
                                    value={tags}
                                    onChange={(e) => handleChangeTags(e)}
                                    name="duration"
                                    placeHolder="Enter Duration"
                                />
                            </div> */}
                            <div className="form-group ">
                                <label className="inputLabel">Durations<span>*</span></label>
                                <Multiselect
                                    displayValue="duration_name"
                                    selectedValues={select_duration}
                                    onRemove={(e) => onRemove_durations(e)}
                                    onSelect={(e) => { onSelect_durations(e) }}
                                    options={durationData}
                                />
                            </div>
                            <div className="form-group large-input-comp">

                                <label className="inputLabel">Description<span>*</span></label>
                                <input type="text-box" className="form-control large-input-button" value={formData.description} placeholder="Example input" onChange={(name) => { setFormData({ ...formData, description: name.target.value || "" }) }} />
                            </div>
                            <div className="form-group" style={{ marginTop: '0', paddingTop: '0' }}>
                                {/* <div className="cloud-comp-image" > */}
                                <div className={imageFiles ? "cloud-comp-exist" : "cloud-comp-image"} style={{ height: "auto", marginTop: "3%", width: '100%', }}>


                                    <div className="inner-div-container" style={{ height: 'auto', marginTop: '.5rem', marginBottom: '.5rem' }}>


                                        {imageFiles && action !== "view" ? <>
                                            <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "80%", height: "55%", margin: '0 auto' }}>
                                                <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{imageName ? imageName : imageFiles?.split("/")?.at(-1).toString()}</p>
                                                <DeleteIcon className="deleteIcon" onClick={handleRemoveImage}></DeleteIcon>
                                            </div>
                                        </> : <></>}


                                        {imageFiles && action === "view" ? <>
                                            <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "80%", height: "55%", margin: '0 auto' }}>
                                                <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{imageName ? imageName : imageFiles?.split("/")?.at(-1).toString()}</p>
                                            </div>
                                        </> : <></>}
                                        {imageFiles === null && action !== "view" ? <>
                                            <div className="imageFiles-dont-exist" >
                                                <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                <label className="btn-image" style={{ border: 'none' }} for="files" class="btn">Select an Image</label>
                                                <input className="form-group " id="files" type="file" multiple onChange={handleImageUpload} style={{ marginTop: '0', visibility: 'hidden' }} />
                                            </div>
                                        </> : <></>}

                                        {action === "view" && imageFiles === null ? <>
                                            <div className="imageFiles-exist" >
                                                <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                <label className="btn-image" style={{ border: 'none' }} for="files" class="btn">Image Upload</label>
                                            </div>
                                        </> : <></>}

                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* {console.log("file name : ",imageFiles?.split("/")?.at(-1).toString())} */}
                        {/* Mentor materials here */}
                        <div className="right-side-buttons row ">
                            <div className="headerComp" >
                                <p className="compTitle">Mentor Material</p>
                            </div>
                            {["pdf", "audio", "video"].map((fileType, index) => {
                                return (

                                    <div className={Object.keys(mentorFiles[fileType]).length === 0 ? "cloud-comp" : "cloud-comp-exist"} style={{ height: "auto", marginTop: "3%" }}>
                                        <div className="inner-div-container">
                                            {Object.keys(mentorFiles[fileType]).length === 0 ? <>

                                                <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                <label style={{ border: 'none' }} className="btn-image1" id="btn-image1" for="files" class="btn">{fileType} Upload</label>


                                            </> : <>
                                            </>}

                                            <div>
                                                {console.log("qwertyu", mentorFiles, fileType)}
                                                {mentorFiles[fileType]?.map((file, index_file) => (

                                                    <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "55%", marginBottom: '8px' }}>
                                                        {console.log("split..", file)}


                                                        {action !== "view" ? <>
                                                            <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{file.name ? file.name.substring(0, 15) : file.path?.split("/")?.at(-1).toString().substring(0, 15)}</p>

                                                            <DeleteIcon className="deleteIcon" onClick={(e) => handleRemoveFile(e, fileType, index_file)}></DeleteIcon>
                                                        </> : <>
                                                            <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{file.name ? file.name : file.path?.split("/")?.at(-1).toString()}</p>

                                                        </>}
                                                        {console.log("1111----")}
                                                        {console.log("justfiles", Object.values(mentorFiles[fileType]))}
                                                    </div>

                                                ))}
                                            </div>
                                            {action !== "view" ? <>
                                                <div style={{ margin: '0 auto', marginBottom: "2%" }} class="image-upload">
                                                    <label for={"Mentorfiles" + index.toString()}>
                                                        <AddIcon className="add-icon" style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} onChange={(e) => { handleFileInput(e, fileType, index) }} />
                                                    </label>
                                                    {/* <AddIcon style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} onChange={(e) => { handleFileInput(e, fileType, index) }} /> */}
                                                    <input className="form-group " id={"Mentorfiles" + index.toString()} type="file" name="file" onChange={(e) => { handleFileInput(e, fileType, index) }} style={{ position: 'relative', marginTop: '1rem', height: '35px', width: "35px", border: '1px solid red', borderRadius: "100px", background: 'transparent' }} ></input>
                                                </div></> : <>

                                            </>}

                                        </div>

                                        <>
                                            {console.log("yes", formData.mentor_material)}
                                            {console.log("yes length", formData.mentor_material.pdf.length)}

                                        </>


                                        {console.log("filetype", fileType)}
                                        {console.log("mentor files", mentorFiles)}
                                        {console.log("student files", studentFiles)}


                                    </div>)

                            })}
                        </div>



                        {/* Student materials here */}
                        <div className="right-side-buttons row">
                            <div className="headerComp" >
                                <p className="compTitle">Student Material</p>
                            </div>
                            {["pdf", "audio", "video"].map((fileType, index) => {
                                return (

                                    <div className={Object.keys(studentFiles[fileType]).length === 0 ? "cloud-comp" : "cloud-comp-exist"} style={{ height: "auto", marginTop: "3%" }}>
                                        <div className="inner-div-container">
                                            {Object.keys(studentFiles[fileType]).length === 0 ? <>
                                                <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                                <label style={{ border: 'none' }} className="btn-image1" id="btn-image1" for="files" class="btn">{fileType} Upload</label>


                                            </> : <>

                                            </>}
                                            {/* <CloudUploadOutlinedIcon style={{ width: "40px", height: "40px", color: "#2AA8C4" }} className="cloud-icon" />
                                        <label style={{ border: 'none' }} className="btn-image1" id="btn-image1" for="files" class="btn">{fileType} Upload</label> */}
                                            <div>
                                                {console.log("split..", { studentFiles })}
                                                {studentFiles[fileType]?.map((file, index_file) => (
                                                    <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "55%", marginBottom: '2px' }}>
                                                        {console.log("split..", file)}

                                                        {action !== "view" ? <>
                                                            <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{file.name ? file.name.substring(0, 15) : file.path?.split("/")?.at(-1).toString().substring(0, 15)}</p>
                                                            <DeleteIcon className="deleteIcon" onClick={(e) => handleRemoveStudentFile(e, fileType, index_file)}>Delete</DeleteIcon>

                                                        </> : <>
                                                            <p style={{ width: "85%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{file.name ? file.name : file.path?.split("/")?.at(-1).toString()}</p>

                                                        </>}

                                                        {console.log("1111----")}
                                                        {console.log("justfiles", Object.values(studentFiles[fileType]))}
                                                    </div>

                                                ))}
                                            </div>
                                            {action !== "view" ? <>
                                                <div style={{ margin: '0 auto', marginBottom: "2%", position: 'relative', marginTop: '0', height: '35px', width: "35px", borderRadius: "100px" }} className="image-upload">
                                                    <label for={"files" + index.toString()}>
                                                        <AddIcon className="add-icon" style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} />
                                                    </label>
                                                    {/* <AddIcon style={{ background: 'white', borderRadius: '100%', height: '35px', width: '35px', zIndex: '100' }} onChange={(e) => { handleFileInputStudent(e, fileType, index) }} /> */}
                                                    <input className="form-group " id={"files" + index.toString()} type="file" name="file" onChange={(e) => { handleFileInputStudent(e, fileType, index) }} style={{ position: 'relative', marginTop: '0', height: '35px', width: "35px", border: '1px solid red', borderRadius: "100px" }} ></input>
                                                </div>
                                            </> : <>

                                            </>}

                                        </div>

                                        <>
                                            {console.log("yes", formData.mentor_material)}

                                        </>


                                        {console.log("filetype", fileType)}
                                        {console.log("datarightnow", Object.values(mentorFiles[fileType]))}

                                    </div>)
                            })}
                        </div>



                    </form>
                </div>
                {action !== "view" ? <>
                    <div className=" btn-comp" style={{ marginTop: '2%' }}>
                        <button type="button" className="btn submit-btn" onClick={(e) => SubmitForm(e)}>Submit</button>
                    </div>
                </> : <></>}
                <ModalConfirmation imageFiles={imageFiles} setImageFiles={setImageFiles} imageName={imageName} setImageName={setImageName} setDeleteFile={user_type === "mentor" ? setMentorFiles : setStudentFiles} deleteFile={user_type === "mentor" ? mentorFiles : studentFiles} is_delete_all={false} formData={formData} user_type={user_type} mentorFiles={mentorFiles} studentFiles={studentFiles} fileType={fileType} fileIndex={fileIndex} data_getter={data_getter} action="delete" modalConfirmationShow={showDeleteModal} setModalConfirmationShow={setShowDeleteModal} onHide={() => setShowDeleteModal(false)} />
                <ModalConfirmation imageFiles={imageFiles} setImageFiles={setImageFiles} imageName={imageName} setImageName={setImageName} is_delete_all={false} formData={formData} setUpdatedImage={setUpdatedImage} updatedImage={updatedImage} data_getter={data_getter} action="imageDelete" modalConfirmationShow={showImageDeleteModal} setModalConfirmationShow={setShowImageDeleteModal} onHide={() => setShowImageDeleteModal(false)} />

            </Modal.Body>
        </Modal >
    )
}
